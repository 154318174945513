<template>
  <v-card
    elevation="4"
    outlined
    class="px-4 pb-2"
    :max-width="$vuetify.breakpoint.xlOnly ? '1530px' : '970px'"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card-title class="pb-1">
      Adicionar nova fazenda
    </v-card-title>
    <v-card-text class="pb-1">
      <v-form
        ref="form"
        dense
        lazy-validation
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <v-autocomplete
              v-model="form.fazenda_id"
              :items="fazendas"
              item-text="razao_social"
              item-value="id"
              label="Fazenda*"
              clearable
              :loading="$asyncComputed.fazendas.updating"
              autocomplete="off"
              :rules="[rules.required]"
              validate-on-blur
              dense
            >
              <template
                #item="{item}"
              >
                <v-container
                  class="rounded-lg mx-n2"
                  fluid
                >
                  <v-row
                    dense
                    justify="space-between"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.razao_social }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.cnpj }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-row>
                </v-container>
              </template>
              <template #prepend-item>
                <div class="d-flex justify-center">
                  <form-fazenda-dialog @fazenda-event="$asyncComputed.fazendas.update" />
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="2"
            xl="2"
          >
            <v-text-field
              v-model="form.cod_produtor_cliente"
              v-mask="'####################'"
              dense
              autocomplete="off"
              label="Código produtor Cliente*"
              :rules="[rules.required]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="2"
            xl="2"
          >
            <v-text-field
              v-model="form.cod_produtor_fornecedor"
              v-mask="'####################'"
              dense
              autocomplete="off"
              :rules="[rules.required]"
              label="Código produtor fornecedor*"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <v-autocomplete
              v-model="form.tecnico_id"
              :items="tecnicos"
              :item-text="(item) => item.nome || item.razao_social"
              item-value="id"
              label="Técnico *"
              clearable
              :loading="$asyncComputed.tecnicos.updating"
              autocomplete="off"
              :rules="[rules.required]"
              validate-on-blur
              dense
            >
              <template #item="data">
                <v-container
                  class="rounded-lg mx-n2"
                  fluid
                >
                  <v-row
                    dense
                    justify="space-between"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.nome || data.item.razao_social }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.cpf || data.item.cnpj }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-row>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="ml-2 mb-1 pt-0">
      <v-btn
        class="text-none text-white mr-1"
        color="error"
        rounded
        @click="cancel"
      >
        <v-icon
          dark
          left
        >
          mdi-minus-circle
        </v-icon>
        Cancelar
      </v-btn>
      <v-btn
        class="text-none text-white"
        color="success"
        rounded
        @click="submitForm()"
      >
        <v-icon
          dark
          left
        >
          mdi-checkbox-marked-circle
        </v-icon>
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import rules from '../../utils/formRules'
  import FormFazendaDialog from './FormFazendaDialog'
  export default {
    components: {
      FormFazendaDialog,
    },
    model: {
      prop: 'form',
      event: 'updateForm',
    },
    props: {
      form: {
        type: Object,
        default: () => ({
          produtor_id: null,
          cod_produtor_fornecedor: null,
          cod_produtor_cliente: null,
          fazenda_id: null,
          tecnico_id: null,
        }),
      },
      showCard: Boolean,
    },
    data () {
      return {
        rules,
        snackbar: { message: '', show: false },
      }
    },
    asyncComputed: {
      fazendas: {
        get () {
          return this.api.listEntidade('fazenda', { quiet: false, redirect: false }).then(
            response => response,
            () => [])
        },
        default: [],
      },
      tecnicos: {
        get () {
          return this.api.listEntidade('tecnico').then(response => response, () => [])
        },
        default: [],
      },
    },
    methods: {
      cancel () {
        this.$emit('updateForm', {
          produtor_id: null,
          cod_produtor_fornecedor: null,
          cod_produtor_cliente: null,
          fazenda_id: null,
        })
      },
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.Swal.fire({
          title: 'Enviando dados!',
          timerProgressBar: true,
          showConfirmButton: false,
          willOpen: () => {
            this.Swal.showLoading()
          },
          didOpen: () => {
            (() => {
              return this.api.cadastrarEntidade(this.form, 'produtorFazenda')
            })().then(
              data => {
                this.Swal.fire({
                  title: 'Fazenda adicionada ao Produtor com sucesso!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.$emit('produtor-fazenda', data)
                  this.cancel()
                })
              },
              error => {
                this.Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  text: error.errors ? error.errors : error.message,
                })
              },
            )
          },
        })
      },
    },
  }
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}
</style>
