<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="800px"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span class="headline">Cadastro de Fazenda</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="$emit('showDialog',false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="px-5">
        <v-card-text>
          <v-form
            ref="formdialog"
            v-model="valido"
            dense
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="formdialog.unidade_negocio_id"
                  :items="unidades"
                  item-text="filial"
                  item-value="id"
                  label="Unidade de negócio*"
                  clearable
                  dense
                  attach
                  :loading="$asyncComputed.unidades.updating"
                  :rules="[rules.minLength(1, '* Obrigatório')]"
                  autocomplete="off"
                  validate-on-blur
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-list-item-content
                      style="max-height: 100px"
                      class="overflow-y-auto"
                    >
                      <v-list-item-title>{{ data.item.filial }}</v-list-item-title>
                      <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="formdialog.razao_social"
                  dense
                  autocomplete="nope"
                  :rules="[rules.required]"
                  label="Razão Social*"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="formdialog.inscricao_estadual"
                  dense
                  autocomplete="off"
                  :rules="[rules.required]"
                  label="Inscrição Estadual*"
                  required
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="formdialog.cnpj"
                  v-mask="maskCnpj"
                  dense
                  autocomplete="nope"
                  :rules="[rules.cnpj]"
                  label="CNPJ"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="formdialog.telefone"
                  v-mask="[...maskTelefone]"
                  :rules="telefoneRules"
                  label="Telefone 1*"
                  type="tel"
                  validate-on-blur
                  dense
                  autocomplete="nope"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="formdialog.pais"
                  dense
                  :disabled="$asyncComputed.paises.updating"
                  :items="paises"
                  item-text="nome"
                  item-value="id"
                  autocomplete="off"
                  :rules="[rules.required]"
                  label="País*"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="formdialog.estado"
                  dense
                  :items="estados"
                  item-text="nome"
                  item-value="id"
                  :rules="[rules.required]"
                  label="Estado*"
                  autocomplete="nope"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="formdialog.cidade"
                  dense
                  :items="cidades"
                  autocomplete="nope"
                  item-text="nome"
                  item-value="id"
                  :rules="[rules.required]"
                  label="Cidade*"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-none text-white"
            color="error"
            rounded
            @click="$emit('showDialog',false)"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import rules from '../../utils/formRules'
  export default {
    model: { prop: 'dialog', event: 'showDialog' },
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        rules,
        maskCnpj: '##.###.###/####-##',
        formdialog: {},
        valido: false,
        maskTelefone: ['(##) ####-####', '(##) #####-####'],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      telefoneRules () {
        return [rules.required, rules.minLength(14, '* Número inválido')]
      },
      showDialog: {
        set (value) {
          this.$emit('showDialog', value)
        },
        get () {
          return this.dialog
        },
      },
      desabilitaBotao () {
        return !this.valido
      },
    },
    created () {
      this.formdialog = this.newForm()
      this.loading = false
    },
    asyncComputed: {
      unidades: {
        get () {
          return this.api.listEntidade('unidadeNegocio', { quiet: false, redirect: false }).then(unidades => unidades)
        },
        default: [],
      },
      paises: {
        get () {
          return this.api.paises().then(paises => paises)
        },
        default: [],
      },
      estados: {
        get () {
          return this.formdialog.pais
            ? this.api.estados(this.formdialog.pais).then(estados => estados)
            : []
        },
        default: [],
      },
      cidades: {
        get () {
          return this.formdialog.estado && this.formdialog.pais
            ? this.api
              .cidades(this.formdialog.pais, this.formdialog.estado)
              .then(cidades => cidades)
            : []
        },
        default: [],
      },
    },
    methods: {
      newForm () {
        return {
          cidade: null,
          estado: null,
          pais: 1,
          unidade_negocio_id: null,
          inscricao_estadual: null,
          razao_social: null,
          telefone: null,
          produtores: [],
          cadastros: [],
        }
      },
      submitForm () {
        if (!this.$refs.formdialog.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        const { telefone, ...formdialog } = this.formdialog
        formdialog.telefones = [telefone]
        this.Swal.fire({
          title: 'Enviando dados!',
          timerProgressBar: true,
          showConfirmButton: false,
          willOpen: () => {
            this.Swal.showLoading()
          },
          didOpen: () => {
            return this.api.cadastrarEntidade(formdialog, 'fazenda')
              .then(
                data => {
                  this.$emit('new-fazenda', data) // evento para selecionar a fazenda recem criada no campo de fazenda do cadastro
                  this.Swal.fire({
                    title: 'Fazenda cadastrada com sucesso!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.formdialog = this.newForm()
                    this.$emit('showDialog', false)
                  })
                },
                error => {
                  this.Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: error.errors ? error.errors : error.message,
                  })
                },
              )
          },
        })
      },
    },
  }
</script>
